<template>
  <div>
    <div class="d-flex align-center">
      <h3>Titel</h3>
      <DimsTooltip>
        Navngiv {{ createAsTemplate ? 'din skabelon' : 'dit indkøb' }}, gerne
        kort og præcist, fx ”Service til vagtplanlægning".
      </DimsTooltip>
    </div>
    <EditDescription :store :createAsTemplate />
    <div class="d-flex flex-row">
      <h3>Del</h3>
    </div>
    <SystemSelection />
    <div class="d-flex flex-row" v-if="showServiceArea">
      <h3>Delaftale</h3>
      <DimsTooltip> Vælg det ydelsesområde, der passer til dit indkøb. </DimsTooltip>
    </div>
    <ServiceArea v-if="showServiceArea" />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { EditDescription, useStore } from '@dims/components';
import { Tender0219, SystemTypesEnum } from '@/models';
import { SystemSelection, ServiceArea } from './steps';

const { createAsTemplate = false } = defineProps<{
  /** when set to true it will create a template instead of a draft */
  createAsTemplate?: boolean }>();
const store = useStore();

const draftTender = computed(() => store.draftTender as Tender0219 | null);

const showServiceArea = computed((): boolean => (
  !createAsTemplate && draftTender.value?.data.systemType !== undefined && draftTender.value.data.systemType !== SystemTypesEnum.PartIII
));

</script>

<style scoped>
h3 {
  margin-top: 5px;
  margin-bottom: 5px;
}
</style>
