<template>
  <v-row align="center" no-gutters>
    <v-col cols="auto">
      <QuestionIcon
        :state="complete ? QuestionStateEnum.COMPLETE : QuestionStateEnum.WARNING"
        :color="complete ? 'primary' : 'accent'"
        :size="18"
      />
    </v-col>
    <v-col>
      <v-btn
        @click="emit('openDialog')"
        :disabled="isLoading"
        :color="complete ? 'primary' : 'accent'"
        :class="{ 'text-decoration-underline': !complete }"
        class="px-2"
        variant="text"
      >
        <slot></slot>
      </v-btn>
    </v-col>
    <v-col cols="auto">
      <v-tooltip location="bottom">
        <template #activator="{ props }">
          <v-chip
            v-bind="props"
            size="small"
            variant="flat"
            :color="complete ? 'secondary' : 'accent'"
            class="ml-auto"
          >{{ selected.length }}</v-chip>
        </template>
        <div>
          <div class="font-weight-bold font">Valgte <slot></slot></div>
          <ul v-for="name in selected" :key="name">
            <li>{{ name }}</li>
          </ul>
          <div v-if="selected.length === 0" class="text-caption">Ingen valgte</div>
          <br />
          <template v-if="deselected">
            <div class="font-weight-bold mt-2">Fravalgte <slot></slot></div>
            <ul v-for="name in deselected" :key="name">
              <li>{{ name }}</li>
            </ul>
            <div v-if="deselected.length === 0" class="text-caption">Ingen fravalgte</div>
          </template>
        </div>
      </v-tooltip>
    </v-col>
  </v-row>
</template>

<script setup lang="ts">
import { QuestionStateEnum } from '@dims/components';

const emit = defineEmits<{ openDialog: [] }>();
const { complete = true, deselected = null, isLoading = false } = defineProps<{
  complete?: boolean,
  selected: string[],
  deselected?: string[] | null,
  isLoading?: boolean,
}>();

</script>
