<template>
  <v-tooltip location="bottom" :disabled="count > 0">
    <template #activator="{ props }">
      <v-chip
        class="ma-2 text-white"
        color="primary"
        variant="flat"
        v-bind="props"
      >
        <v-progress-circular
          class="mr-2"
          v-if="isRefreshing"
          size="20"
          indeterminate
        />
        <v-avatar v-else start class="bg-white text-primary">
          {{ count }}
        </v-avatar>
        {{ count === 1 ? 'service' : 'services' }}
      </v-chip>
    </template>
    <span>
      Der er ingen Services, der opfylder dine krav og valg i
      kravspecifikationen. Du kan genoverveje dine valg.
    </span>
  </v-tooltip>
</template>
<script setup lang="ts">
import { computed } from 'vue';
import { Tender0219 } from '@/models';

const { tender, isRefreshing = false } = defineProps<{
  tender: Tender0219,
  isRefreshing?: boolean,
}>();

const count = computed(() => new Set(tender.data.filteredVariants?.map((v) => v.productId))
  .size);

</script>
