<template>
  <div>
    <v-radio-group v-model="selectedSystemType" class="ml-6" :hide-details="true" density="comfortable">
      <div class="d-flex">
        <v-radio
          color="accent"
          :value="systemTypePartI"
        >
          <template #label>
            {{ systemTypeLabel(systemTypePartI) }}
            <DimsTooltip>
              <p>
                Du skal bruge Del I hvis du køber ind for stat, region eller øvrige offentlige organisationer (ikke kommuner).
              </p>
            </DimsTooltip>
          </template>
        </v-radio>
      </div>
      <div class="d-flex">
        <v-radio
          color="accent"
          :value="systemTypePartII"
        >
          <template #label>
            {{ systemTypeLabel(systemTypePartII) }}
            <DimsTooltip>
              <p>
                Du skal bruge Del II, hvis du køber ind for en kommune.
              </p>
            </DimsTooltip>
          </template>
        </v-radio>
      </div>
      <div class="d-flex">
        <v-radio
          color="accent"
          :label="`${systemTypeLabel(systemTypePartIII)}`"
          :value="systemTypePartIII"
        >
          <template #label>
            {{ systemTypeLabel(systemTypePartIII) }}
            <DimsTooltip>
              <p>
                Ved indkøb på det dynamisk indkøbssystem er det muligt at vælge flere kategorier, hvilket kan være relevant, såfremt man ønsker at indkøbe produkt-'suites'.
              </p>
            </DimsTooltip>
          </template>
        </v-radio>
      </div>
    </v-radio-group>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { AwardCriteriaType, AwardForm } from '@dims/components';
import { SystemTypesEnum, labelForSystemType } from '@/models';
import { useStore0219 } from '@/store/store0219';

const store = useStore0219();
const systemTypePartI = SystemTypesEnum.PartI;
const systemTypePartII = SystemTypesEnum.PartII;
const systemTypePartIII = SystemTypesEnum.PartIII;

const selectedSystemType = computed({
  get(): SystemTypesEnum | undefined {
    let systemType;
    if (store.draftTender) {
      systemType = store.draftTender.data.systemType;
    }
    return systemType ?? undefined;
  },
  set(systemType: SystemTypesEnum | undefined) {
    if (store.draftTender && systemType) {
      let awardCriteriaType: AwardCriteriaType = 'TCO';
      if (systemType === SystemTypesEnum.PartIII) {
        awardCriteriaType = 'BPQRatio';
      }
      store.setDraftTender({
        ...store.draftTender,
        awardForm: getAwardForm(systemType),
        awardCriteriaType,
        data: {
          ...store.draftTender.data,
          systemType,
        },
      });
    }
  },
});

function systemTypeLabel(type: SystemTypesEnum): string {
  return labelForSystemType(type);
}

function isDirectAward(systemType: SystemTypesEnum): boolean {
  return (
    systemType === SystemTypesEnum.PartI
      || systemType === SystemTypesEnum.PartII
  );
}

function getAwardForm(systemType: SystemTypesEnum): AwardForm {
  return isDirectAward(systemType) ? 'DirectAward' : 'DIS';
}

</script>
