import { AgreementSpecific } from '@dims/components';
import FilterConfiguration from './services/FilterConfiguration';
import bpqCriteria from './services/bpqCriteria';
import PricePointValidator from './components/Tender/Specification/PricePointValidator';

const agreementSpecific: AgreementSpecific = {
  createFilter() { return new FilterConfiguration(); },
  bpqCriteria,
  pricePointValidator: PricePointValidator,
};

export default agreementSpecific;
