<template>
  <v-table>
    <template #default>
      <thead v-if="headers">
        <tr>
          <th v-for="header in headers" :key="header.value" class="text-left">
            {{ header.value }}
            <CalculationTag v-if="header.tag" :isHovered="isHovered(header.tag)">{{ header.tag }}</CalculationTag>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(row, rowIdx) in table.rows" :key="rowIdx" :class="rowClass(row)">
          <td v-for="(cell, cellIdx) in row.cells" :key="cellIdx" :class="isPrice(cell.unit) ? ' align-end' : ''">
            {{
              isPrice(cell.unit)
                ? $format.currency2(price(cell.value))
                : `${cell.value}${unit(cell.unit)}`
            }}
            <CalculationTag v-if="tag(rowIdx, cellIdx)" :isHovered="isHovered(tag(rowIdx, cellIdx))">{{ tag(rowIdx, cellIdx) }}</CalculationTag>
          </td>
        </tr>
      </tbody>
    </template>
  </v-table>
</template>

<script setup lang="ts">

import { labelForUnit, PricePointValueUnit, TcoCalculationTable, TcoCalculationTableRow, CalculationTag } from '@dims/components';

interface Header {
  value: string;
  tag?: string;
}

interface HeaderTag {
  row: number;
  cell: number;
  value: string;
}

const { tags = [], hoveredTag = undefined } = defineProps<{
  table: TcoCalculationTable,
  headers: Header[],
  tags?: HeaderTag[],
  hoveredTag?: string,
}>();

function tag(rowIdx: number, cellIdx: number) {
  return (
    tags.find((t) => t.row === rowIdx && t.cell === cellIdx)
      ?.value ?? ''
  );
}

function rowClass(row: TcoCalculationTableRow) {
  return row.cssClass ?? '';
}

function isHovered(headerTag: string) {
  return hoveredTag === headerTag;
}

function unit(valueUnit: string) {
  return labelForUnit(valueUnit as PricePointValueUnit);
}

function isPrice(valueUnit: string) {
  return (valueUnit as PricePointValueUnit) === PricePointValueUnit.Price;
}

function price(value: string) {
  return Number(value);
}

</script>
<style scoped>
  .align-end {
    text-align: end;
  }

  .bold {
    font-weight: bold;
  }
</style>
