import { PricePointDropdownValues } from '@dims/components';

const pricePointDropdownValues: PricePointDropdownValues = {
  getPricePointDropdownValues(_type: string, _configurationId: string, _groupId: string): string[] {
    // Empty implementation to make sure that function can be injected into common code
    return [];
  },
};

export default pricePointDropdownValues;
