import { TemplateQuestionPanelState, Tab, TabType, Questionnaire } from '@dims/components';

const customerSpecificationTabs: Tab[] = [
  {
    title: 'Behov',
    section: 0,
    panelState: TemplateQuestionPanelState.INIT,
  },
  {
    title: 'Specifikationer',
    section: 1,
    panelState: TemplateQuestionPanelState.INIT,
  }];

const getCustomerSpecificationTabs: (isDIS: boolean) => Tab[] = (isDIS) => {
  const directAwardTabs = [
    {
      title: 'Tilkøb',
      section: 2,
      panelState: TemplateQuestionPanelState.INIT,
    },
    {
      title: 'Kundeforhold',
      section: 3,
      panelState: TemplateQuestionPanelState.INIT,
    },
    {
      title: 'Services',
      section: 4,
      panelState: TemplateQuestionPanelState.INIT,
      customClass: 'PimIntegrationServicesTab',
      customStepper: 'PimStepper',
      dependentOnAllPrevious: true,
    },
  ];
  const disTabs = [
    {
      title: 'Implementering',
      section: 2,
      panelState: TemplateQuestionPanelState.INIT,
    },
    {
      title: 'Organisation og samarbejde',
      section: 3,
      panelState: TemplateQuestionPanelState.INIT,
    },
    {
      title: 'Sikkerhed',
      section: 4,
      panelState: TemplateQuestionPanelState.INIT,
    },
    {
      title: 'Grønne krav',
      section: 5,
      panelState: TemplateQuestionPanelState.INIT,
    },
    {
      title: 'Tilkøb',
      section: 6,
      panelState: TemplateQuestionPanelState.INIT,
    },
    {
      title: 'Tilbudsliste',
      section: 7,
      panelState: TemplateQuestionPanelState.INIT,
    },
  ];
  if (isDIS) return customerSpecificationTabs.concat(disTabs);
  return customerSpecificationTabs.concat(directAwardTabs);
};

type TabNumbers = [number, number, number, number, number, number, number, number];

function supplierSpecificationTabs(p: TabNumbers) {
  const result = [
    {
      title: 'Dit tilbud',
      section: p[0],
      panelState: TemplateQuestionPanelState.INIT,
    },
    {
      title: 'Behov',
      section: p[1],
      panelState: TemplateQuestionPanelState.INIT,
    },
    {
      title: 'Specifikationer',
      section: p[2],
      panelState: TemplateQuestionPanelState.INIT,
    },
    {
      title: 'Implementering',
      section: p[3],
      panelState: TemplateQuestionPanelState.INIT,
    },
    {
      title: 'Organisation og Samarbejde',
      section: p[4],
      panelState: TemplateQuestionPanelState.INIT,
    },
    {
      title: 'Sikkerhed',
      section: p[5],
      panelState: TemplateQuestionPanelState.INIT,
    },
    {
      title: 'Grønne Krav',
      section: p[6],
      panelState: TemplateQuestionPanelState.INIT,
    },
    {
      title: 'Tilkøb',
      section: p[7],
      panelState: TemplateQuestionPanelState.INIT,
    },
  ];
  return result.filter((t) => t.section !== -1);
}

function supplierOfferTabs(p: number) {
  return [
    {
      title: 'Prisafgivelse',
      section: p,
      panelState: TemplateQuestionPanelState.INIT,
    },
  ];
}

const vejledningSectionId = '4f9fb180-a560-4cde-8915-1cc7df61041a';
const behovSectionId = '6ba44843-fe4e-497d-adc6-4a24ad816859';
const specifikationerSectionId = '93c65c1e-5c64-4cd0-8012-cb54ebc8403d';
const implementationSectionId = '12fa5236-2bd0-48d1-9156-db8d0278c33f';
const organisationSectionId = '95c0ec92-af8d-437e-a61f-dca971c9bbef';
const sikkerhedSectionId = '53864683-3adf-43f8-b739-849a4274d0d9';
const groenneKravSectionId = '91c249a9-59d3-456b-96c8-adfa29bc916c';
const tilkoebSectionId = '3fb9b35e-f1c7-4979-af8d-3504af0d42c7';
const tilbudslisteSectionId = '9f7e4ead-6a1d-4503-9510-c6ae5266c10c';
let supplierOfferTabNumber = 7;
let supplierSpecificationTabNumbers = new Array(8) as TabNumbers;

function sectionIndex(offerQuestionnaire: Questionnaire | null, id: string) {
  if (offerQuestionnaire) {
    return offerQuestionnaire.questions.findIndex((q) => q.questionId === id);
  }
  return -1;
}

export function setSupplierSpecificationTabs(offerQuestionnaire: Questionnaire|null): TabNumbers {
  supplierSpecificationTabNumbers = [
    sectionIndex(offerQuestionnaire, vejledningSectionId),
    sectionIndex(offerQuestionnaire, behovSectionId),
    sectionIndex(offerQuestionnaire, specifikationerSectionId),
    sectionIndex(offerQuestionnaire, implementationSectionId),
    sectionIndex(offerQuestionnaire, organisationSectionId),
    sectionIndex(offerQuestionnaire, sikkerhedSectionId),
    sectionIndex(offerQuestionnaire, groenneKravSectionId),
    sectionIndex(offerQuestionnaire, tilkoebSectionId),
  ];
  return supplierSpecificationTabNumbers;
}

export function setSupplierPriceTab(offerQuestionnaire: Questionnaire|null):number {
  supplierOfferTabNumber = offerQuestionnaire?.questions.findIndex((q) => q.questionId === tilbudslisteSectionId) ?? 6;
  return supplierOfferTabNumber;
}

const questionnaireTabConfig = {
  getTabs: (tenderId: string, type: TabType, isDIS: boolean): Promise<Tab[]> => {
    let tabs: Tab[] = [];

    switch (type) {
      case 'CustomerSpecification':
        tabs = getCustomerSpecificationTabs(isDIS);
        break;
      case 'SupplierSpecification':
        tabs = supplierSpecificationTabs(supplierSpecificationTabNumbers);
        break;
      case 'SupplierOffer':
        tabs = supplierOfferTabs(supplierOfferTabNumber);
        break;
    }

    return new Promise((resolve) => {
      resolve(tabs);
    });
  },
};

export default questionnaireTabConfig;
