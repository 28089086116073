import { Offer, Supplier, TenderSummary } from '@dims/components';
import { SystemTypesEnum, TenderData0219 } from '@/models';

export default class FilterConfiguration {
  properties = [];

  filterTender(_tender: TenderSummary) {
    return true;
  }
  filterOffer(_offer: Offer) {
    return true;
  }
  filterLot(_lot: { lotNo:string, title: string }) {
    return true;
  }
  filterSupplier(tenderData: TenderData0219, supplier: Supplier): boolean {
    switch (tenderData.systemType) {
      case SystemTypesEnum.PartI:
        return supplier.subAgreementIds?.includes('02190123') ?? false;
      case SystemTypesEnum.PartII:
        return supplier.subAgreementIds?.includes('02190223') ?? false;
      case SystemTypesEnum.PartIII:
        return supplier.subAgreementIds?.includes('02190323') ?? false;
      default:
        return true;
    }
  }
}
