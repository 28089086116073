<template>
  <div>
    <LoadingSpinner
      :visible="isLoading"
      loadingMessage="Henter uddrag af krav"
    />
    <div v-if="!isLoading">
      <v-card
        v-if="questionnaire && tender"
        class="pa-3 scroll"
        color="#fff9ef"
        :height="specificationViewerHeight ? specificationViewerHeight : ''"
      >
        <div class="header">
          <div class="float-right">
            <v-btn
              variant="text"
              @click="downloadPdf()"
            >Download som PDF<v-icon end>mdi-download</v-icon></v-btn>
            <LoadingSpinner
              :visible="isDownloading"
              loadingMessage="Danner PDF..."
            />
            <div class="d-flex align-center text-accent">
              <v-btn
                @click="expandingPanel(false)"
                variant="text"
                color="accent"
              >Fold ind</v-btn>|
              <v-btn
                @click="expandingPanel(true)"
                variant="text"
                color="accent"
              >Fold ud</v-btn>
            </div>
          </div>
          <div>{{ tender.user.companyName }}</div>
          <div>{{ tender.description }}</div>
          <div>{{ tender.friendlyId }}</div>
        </div>
        <v-card-title class="text-primary">
          <span class="title-text">Uddrag af leveringskontraktens bilag</span>
        </v-card-title>
        <v-card-subtitle class="mb-n4">
          Samtlige krav fremgår af ‘Offentliggjort materiale’ på fanen ‘Overblik’.
        </v-card-subtitle>
        <v-card-text>
          <div v-for="tab in specificationTabs" :key="tab.questionId">
            <h2>{{ tab.title }}</h2>
            <br />
            <div
              v-for="section in tab.questions || []"
              :key="section.questionId"
            >
              <ReadOnlyTemplateQuestion
                :section
                :question="section"
                :questionnaire
                :level="1"
                :panelState
                @panelsUpdate="panelsUpdate"
                :tender
              />
            </div>
          </div>
          <br />
          <div v-if="questionnaire && files">
            <h1 class="font-weight-bold mt-0 mb-6">Bilag</h1>
            <v-card class="bg-white">
              <v-card-text>
                <FileListDisplay
                  :questionnaire
                  :files
                />
              </v-card-text>
            </v-card>
          </div>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, computed } from 'vue';
import {
  Tender,
  useStore,
  Questionnaire,
  downloadFile,
  TemplateQuestionPanelState,
  ReadOnlyTemplateQuestion,
  useBackend,
  FileDisplay,
  FileListDisplay,
  getTenderFiles,
} from '@dims/components';

const { tender, specificationViewerHeight } = defineProps<{
  tender: Tender,
  specificationViewerHeight?: number}>();
const store = useStore();
const backend = useBackend();
const panelState = ref<TemplateQuestionPanelState>(TemplateQuestionPanelState.INIT);
const questionnaire = ref<Questionnaire | null>(null);
const files = ref<FileDisplay[] | null>(null);
const isLoading = ref(false);
const isDownloading = ref(false);

onMounted(async () => {
  isLoading.value = true;
  await fetchQuestionnaire();
  if (questionnaire.value) {
    files.value = await getTenderFiles(questionnaire.value.id);
  }
  isLoading.value = false;
});

async function fetchQuestionnaire() {
  try {
    const deepQuestionnaire = await store.backend.questionnaireService.getReadonlyQuestionnaire(
      tender.id,
      'specification',
    );
    questionnaire.value = deepQuestionnaire;
  } catch (e) {
    console.error(e);
    const error = e instanceof Error ? e.message : String(e);
    store.setSnackbarText(error);
  }
}

async function downloadPdf() {
  if (questionnaire.value) {
    isDownloading.value = true;
    try {
      const pdfBlob = await backend.pdfService.specificationAsPdf(tender);
      downloadFile(pdfBlob, `Kravspecifikation-${tender.friendlyId}.pdf`);
    } catch (e) {
      console.error(e);
      store.setSnackbarText('Kan ikke hente fil');
    } finally {
      isDownloading.value = false;
    }
  }
}

function expandingPanel(value: boolean) {
  panelState.value = value
    ? TemplateQuestionPanelState.EXPANDED
    : TemplateQuestionPanelState.COLLAPSED;
}

function panelsUpdate() {
  panelState.value = TemplateQuestionPanelState.CHANGED;
}
const specificationTabs = computed(() => questionnaire.value?.questions ?? []);

</script>

<style scoped>
.scroll {
  overflow-y: scroll;
}

.header {
  margin: 16px;
  font-size: 0.875rem;
}

.title-text {
  font-size: 1.5em;
  font-weight: 700;
}
</style>
