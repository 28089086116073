<template>
  <v-card>
    <v-row no-gutters>
      <v-col class="scroller" :style="scrollerStyle">
        <v-card class="card">
          <v-card-text>
            <h2>Evalueringsteknisk pris (beregning)</h2>
            <h3 class="pb-2">Generelle data</h3>
            <TcoCalculation
              :table="getTable(commonSection)!"
              :headers="getHeaders(commonSection)!"
              :hoveredTag
              :tags="[]"
            />
            <h3 class="pb-2">Samlet beregning</h3>
            <v-row>
              <v-col>
                <TcoCalculation
                  :table="getTable(totalsSection)!"
                  :headers="getHeaders(totalsSection)!"
                  :hoveredTag
                  :tags="[]"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="3">
        <v-card color="#fff9ef" class="fill-height" flat>
          <v-card-text ref="description">
            <TcoCalculationDescription />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-card>
</template>

<script setup lang="ts">
import { ref, onMounted, nextTick } from 'vue';
import { TcoCalculationTable } from '@dims/components';
import TcoCalculationDescription from './TcoCalculationDescription.vue';
import TcoCalculation from './TcoCalculation.vue';

const { tables } = defineProps<{ tables: Record<string, TcoCalculationTable> }>();
const scrollerStyle = ref({ maxHeight: undefined as string | undefined });
const hoveredTag = ref<string>();

const totalsSection = 'Totals';
const commonSection = 'CommonData';

const description = ref<Element>();

onMounted(() => {
  // Set height of left column to height of description
  // We wait for next tick because mounted() does not guarantee that the DOM has finished rendering
  void nextTick(() => {
    if (description.value) {
      const height = description.value.clientHeight;
      scrollerStyle.value.maxHeight = `${height}px`;
    }
  });
});

const headers: Record<string, {value: string, tag: string}[]> = {
  Totals:
    [
      {
        value: 'Beskrivelse',
        tag: '',
      },
      {
        value: '',
        tag: '',
      },
      {
        value: '',
        tag: '',
      },
      {
        value: 'Total',
        tag: '',
      },
    ],
  CommonData:
    [
      {
        value: 'Beskrivelse',
        tag: '',
      },
      {
        value: '',
        tag: '',
      },
      {
        value: '',
        tag: '',
      },
      {
        value: 'Værdi',
        tag: '',
      },
    ],
};

function getTable(section: string) {
  return tables[section];
}

function getHeaders(section: string) {
  return headers[section];
}

</script>

<style scoped>
.card {
  margin: 2em;
  background: rgb(var(--v-theme-canvas-base)) !important;
}

h2 {
  color: rgb(var(--v-theme-primary));
  margin-bottom: 1em;
  margin-top: 0;
  text-transform: uppercase;
}

.scroller {
  overflow-y: scroll !important;
}
</style>
