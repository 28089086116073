<template>
  <div>
    <v-tooltip location="right" max-width="30vw">
      <template #activator="{ props }">
        <v-btn
          v-bind="props"
          class="ml-1 mt-n1"
          icon
          size="x-small"
          :color="btnColor"
          @click.stop="openTcoPriceDocumentationDialog()"
        >
          <v-icon size="small"> mdi-information </v-icon>
        </v-btn>
      </template>
      <p>Klik for at se dokumentation af udregningen.</p>
    </v-tooltip>
    <v-dialog width="1400" v-model="tcoPriceDocumentationDialog">
      <TcoDocumentation
        @closeDialog="closeDialog()"
        :includeCloseButton="true"
      >
      </TcoDocumentation>
    </v-dialog>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import TcoDocumentation from '@/components/Tender/Evaluation/TcoDocumentation.vue';

const { btnColor } = defineProps<{ btnColor: string }>();
const tcoPriceDocumentationDialog = ref(false);

function openTcoPriceDocumentationDialog() {
  tcoPriceDocumentationDialog.value = true;
}

function closeDialog() {
  tcoPriceDocumentationDialog.value = false;
}

</script>
