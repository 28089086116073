import { GlobalListValue, Product } from '@dims/components';
import { IntegrationPublicComponent } from '@/models/PIM/GlobalLists/IntegrationPublicComponent';
import { Variant0219 } from '@/models/PIM/Variant0219';
import { TenderData0219, ServiceLevelsEnum } from '@/models';

/**
 * Retrieves a collection of service levels based on the given service level.
 * @param serviceLevel The service level to retrieve the collection from.
 * @returns An array of service levels.
 */
const getServiceLevelsCollection = (
  serviceLevel: ServiceLevelsEnum,
): ServiceLevelsEnum[] => {
  const allServiceLevels: ServiceLevelsEnum[] = Object.values(ServiceLevelsEnum);
  const serviceLevelIndex = allServiceLevels.indexOf(serviceLevel);
  if (serviceLevelIndex === -1) {
    return [];
  }
  return allServiceLevels.slice(serviceLevelIndex, allServiceLevels.length);
};

const includesServiceLevel = (product: Product, serviceLevels: ServiceLevelsEnum[]): boolean => {
  if ('serviceLevel_0219' in product) {
    return serviceLevels.includes(product.serviceLevel_0219 as ServiceLevelsEnum);
  }
  return false;
};

const pimService = {
  getServiceLevelsCollection,
  filterVariantsFromTenderData: (
    variants: Variant0219[],
    tenderData: TenderData0219,
  ): Variant0219[] => {
    const serviceLevels = tenderData.serviceLevel
      ? getServiceLevelsCollection(tenderData.serviceLevel)
      : [];

    return variants.filter(
      (variant) => serviceLevels.includes(
          variant.serviceLevel_0219 as ServiceLevelsEnum,
      )
        && variant.useCases_0219?.every(
          (usecase) => tenderData.serviceArea
            && usecase.serviceCategory.serviceCategoryId
              === tenderData.serviceArea.valueOf()
            && tenderData.systemType
            && usecase.part_0219.partId_0219 === tenderData.systemType.valueOf(),
        ),
    );
  },

  filterIntegrationPublicComponentFromTenderData: (
    integrationPublicComponents: GlobalListValue<IntegrationPublicComponent>[],
    tenderData: TenderData0219,
  ) => integrationPublicComponents.filter(
    (globalListValue) => tenderData.serviceArea
        && tenderData.serviceArea.valueOf()
          === globalListValue.value?.integrationPublicComponentUseCase_0219
            .serviceCategory.serviceCategoryId
        && tenderData.systemType
        && globalListValue.value.integrationPublicComponentUseCase_0219.part_0219
          .partId_0219 === tenderData.systemType.valueOf(),
  ),

  filterReferencesFromTenderData: (
    references: Product[],
    tenderData0219: TenderData0219,
  ): Product[] => {
    const serviceLevels = tenderData0219.serviceLevel
      ? getServiceLevelsCollection(tenderData0219.serviceLevel)
      : [];
    return references.filter((reference) => includesServiceLevel(reference, serviceLevels));
  },
};

export default pimService;
