import { FunctionalAddOn0219 } from '@/models/PIM/FunctionalAddOn0219';
import { Product0219 } from '@/models/PIM/Product0219';
import { SupplierSpecificIntegration0219 } from '@/models/PIM/SupplierSpecificIntegration0219';
import { Variant0219 } from '@/models/PIM/Variant0219';

export default class PimIntegrationHelper {
  getVariantsByProduct(variants: Variant0219[], product: Product0219 | null) {
    return variants.filter((v) => v.productId === product?.id);
  }

  totalFunctionalAddOns(functionalAddOns: FunctionalAddOn0219[], product: Product0219) {
    const addOns = functionalAddOns.filter((f) => f.supplierProductId?.includes(product.id.toString()));
    return addOns.filter((v, i, a) => a.findIndex((t) => (t.name === v.name)) === i);
  }

  isSupplierSpecificIntegrationsCompleted(variants: Variant0219[], integrations: SupplierSpecificIntegration0219[], product: Product0219) {
    const total = this.totalSupplierSpecificIntegrations(integrations, product).length;
    const selected = this.selectedSupplierSpecificIntegrations(variants, product).length;
    const deselected = this.deselectedSupplierSpecificIntegrations(variants, product).length;
    return total === selected + deselected;
  }

  isFunctionalAddOnsCompleted(variants: Variant0219[], functionalAddOns: FunctionalAddOn0219[], product: Product0219) {
    const total = this.totalFunctionalAddOns(functionalAddOns, product).length;
    const selected = this.selectedFunctionalAddOns(variants, product).length;
    const deselected = this.deselectedFunctionalAddOns(variants, product).length;
    return total === selected + deselected;
  }

  uniqueVariants(variants: Variant0219[], product: Product0219) {
    const vars = this.getVariantsByProduct(variants, product);
    return vars.filter((v, i, a) => a.findIndex((t) => (t.serviceVariantName_0219 === v.serviceVariantName_0219)) === i);
  }

  uniqueFunctionalAddOns(variants: Variant0219[], product: Product0219) {
    const variantsForProduct = this.getVariantsByProduct(variants, product);
    if (variantsForProduct.length > 0) {
      const selectedFunctionalAddOns = variantsForProduct[0]?.selectedFunctionalAddOns;
      if (selectedFunctionalAddOns) {
        return selectedFunctionalAddOns.filter(
          (addOn, index, array) => array.findIndex((t) => t.name === addOn.name) === index,
        );
      }
    }
    return [];
  }

  uniqueSupplierSpecificIntegrations(variants: Variant0219[], product: Product0219) {
    const variantsForProduct = this.getVariantsByProduct(variants, product);
    if (variantsForProduct.length > 0) {
      const supplierSpecificIntegrations = variantsForProduct[0]?.selectedSupplierSpecificIntegrations;
      if (supplierSpecificIntegrations) {
        return supplierSpecificIntegrations.filter(
          (integration, index, array) => array.findIndex((t) => t.name === integration.name) === index,
        );
      }
    }
    return [];
  }

  totalSupplierSpecificIntegrations(integrations: SupplierSpecificIntegration0219[], product: Product0219) {
    const supplierSpecificIntegrations = integrations.filter((f) => f.supplierProductId?.includes(product.id.toString()));
    return supplierSpecificIntegrations.filter((v, i, a) => a.findIndex((t) => (t.name === v.name)) === i);
  }

  selectedFunctionalAddOns(variants: Variant0219[], product: Product0219) {
    return this.uniqueFunctionalAddOns(variants, product).filter((s) => s.selected === true);
  }

  deselectedFunctionalAddOns(variants: Variant0219[], product: Product0219) {
    return this.uniqueFunctionalAddOns(variants, product).filter((s) => s.selected === false);
  }

  selectedSupplierSpecificIntegrations(variants: Variant0219[], product: Product0219) {
    return this.uniqueSupplierSpecificIntegrations(variants, product).filter((s) => s.selected === true);
  }

  deselectedSupplierSpecificIntegrations(variants: Variant0219[], product: Product0219) {
    return this.uniqueSupplierSpecificIntegrations(variants, product).filter((s) => s.selected === false);
  }

  supplierSpecificIntegrationServiceLevels(integrations: SupplierSpecificIntegration0219[], integration: SupplierSpecificIntegration0219): string[] {
    return integrations
      .filter((v) => v.name === integration.name)
      .map((v) => v.serviceLevel_0219)
      .filter((serviceLevel) => serviceLevel !== undefined);
  }

  filteredProducts(products: Product0219[], variants: Variant0219[]) {
    return products.filter((p) => variants.some((v) => v.productId === p.id));
  }

  deselectionReason(variants: Variant0219[], product: Product0219) {
    const filteredVariants = this.getVariantsByProduct(variants, product);
    return filteredVariants.length > 0 ? filteredVariants[0]?.deselectionReason : '';
  }
}

export const pimIntegrationHelper = new PimIntegrationHelper();
