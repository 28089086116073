<template>
  <div>
    <v-row v-if="distinctVariantCount" class="bg-canvas" no-gutters>
      <v-col>
        <div class="scroll pa-5">
          <v-row data-cy="service-list">
            <v-col
              lg="4"
              md="6"
              sm="12"
              v-for="product in filteredProducts"
              :key="product.id"
            >
              <PimIntegrationProduct
                :product
                :variants="filteredVariants"
                :functionalAddOns="functionalAddOnsRefs"
                :supplierSpecificIntegrations="supplierSpecificIntegrationsRefs"
                :isLoading
                @save="onSave"
                @accept="onAccept"
                @reject="onReject"
                @openDialog="openDialog"
              >
                <v-tooltip location="bottom">
                  <template #activator="{ props }">
                    <v-btn @click="openDialog(product)" v-bind="props" icon :disabled="isLoading" variant="text">
                      <v-icon>{{
                        isProductDeselected(product)
                          ? 'mdi-file-outline'
                          : 'mdi-pencil-outline'
                      }}</v-icon>
                    </v-btn>
                  </template>
                  <span>{{
                    isProductDeselected(product)
                      ? 'Preview service'
                      : 'Rediger service'
                  }}</span>
                </v-tooltip>
              </PimIntegrationProduct>
            </v-col>
          </v-row>
        </div>
      </v-col>
      <v-col cols="3" class="summary">
        <div class="scroll">
          <v-card flat color="transparent">
            <v-card-text>
              <PimIntegrationServiceSummary
                :products
                :isLoading
                @closeDialog="emit('closeDialog')"
              />
            </v-card-text>
          </v-card>
        </div>
      </v-col>
    </v-row>
    <div v-else class="d-flex align-center justify-center">
      <v-alert
        border="start"
        color="secondary"
        class="my-5"
        icon="mdi-information-outline"
        prominent
      >
        <div>
          Der er ingen Services, der opfylder dine krav og valg i kravspecifikationen.
        </div>
        <div>
          Du kan genoverveje dine valg i de foregående faner.
        </div>
      </v-alert>
    </div>
    <PimIntegrationServiceDialog
      v-if="selectedProduct"
      v-model="pimIntegrationServiceDialog"
      :tender
      :product="selectedProduct"
      :filteredVariants
      :functionalAddOnsRefs
      :supplierSpecificIntegrationsRefs
      :requestedUseCases
      @save="onSave"
    />
    <LoadingSpinner :loadingMessage :visible="isInitializing" />
  </div>
</template>
<script setup lang="ts">
import {
  useBackend,
  Product,
  useStore,
} from '@dims/components';
import { ref, watch, onMounted, computed } from 'vue';
import PimIntegrationServiceDialog from './PimIntegrationServiceDialog.vue';
import { Variant0219 } from '@/models/PIM/Variant0219';
import { Tender0219, SystemTypesEnum, UpdateTenderRequest0219 } from '@/models';
import { Product0219 } from '@/models/PIM/Product0219';
import { pimIntegrationHelper } from '@/services/pimIntegrationHelper';
import { FunctionalAddOn0219 } from '@/models/PIM/FunctionalAddOn0219';
import { SupplierSpecificIntegration0219 } from '@/models/PIM/SupplierSpecificIntegration0219';
import PimIntegrationServiceSummary from './PimIntegrationServiceSummary.vue';
import PimIntegrationProduct from './PimIntegrationProduct.vue';
import { questionnaireHelper0219 } from '@/services/questionnaireHelper0219';

interface ProductReferences_0219 {
  readonly functionalAddOn_0219: FunctionalAddOn0219[];
  readonly supplierSpecificIntegration_0219: SupplierSpecificIntegration0219[];
}

const emit = defineEmits<{ closeDialog: [] }>();
const { tender } = defineProps<{ tender: Tender0219 }>();
const backend = useBackend();
const store = useStore();
const isLoading = ref(false);
const isInitializing = ref(false);
const loadingMessage = ref('Vent venligst');
const pimIntegrationServiceDialog = ref(false);
const products = ref<Product0219[]>([]);
const selectedProduct = ref<Product0219 | null>(null);
const filteredVariants = ref<Variant0219[]>([]);
const requestedUseCases = ref<string[]>([]);
const functionalAddOnsRefs = ref<FunctionalAddOn0219[]>([]);
const supplierSpecificIntegrationsRefs = ref<SupplierSpecificIntegration0219[]>([]);

watch(
  () => tender.data.filteredVariants,
  async (newValue?: Variant0219[], oldValue?: Variant0219[]) => {
    if (!newValue || !oldValue || !arraysHaveSameElements(oldValue.map((v) => v.id), newValue.map((v) => v.id))) {
      await fetchData();
    }
  },
);

onMounted(async () => {
  if (!products.value.length) {
    isInitializing.value = true;
    await fetchData();
    isInitializing.value = false;
  }
});

async function fetchData() {
  isLoading.value = true;
  filteredVariants.value = tender.data.filteredVariants ?? [];
  const productsConst = await getProducts();
  await Promise.all(productsConst.map((p) => loadReferences(p)));
  const { questions } = await fetchQuestionnaire();
  if (tender.data.systemType === SystemTypesEnum.PartI) {
    requestedUseCases.value = questionnaireHelper0219.loadUseCases(questions, questionnaireHelper0219.useCasesPartIQuestionId);
  } else if (tender.data.systemType === SystemTypesEnum.PartII) {
    requestedUseCases.value = questionnaireHelper0219.loadUseCases(questions, questionnaireHelper0219.useCasesPartIIQuestionId);
  }

  // Set the state on each products according to their associated variants.
  // Every variant will have the same state, as the products are not stored in the database.
  const updatedProducts = productsConst.map((product) => {
    const variants = getVariantsByProduct(product);
    const newState = variants[0]?.state ?? calculateProductState(variants, product);
    const updatedProduct = { ...product, state: newState };
    return updatedProduct;
  });

  products.value = updatedProducts;
  isLoading.value = false;
}

async function loadReferences(product: Product) {
  const references = await backend.pimDataService.fetchProductReferences<ProductReferences_0219>(product.id);
  functionalAddOnsRefs.value.push(...references.functionalAddOn_0219);
  supplierSpecificIntegrationsRefs.value.push(...references.supplierSpecificIntegration_0219);
}

async function getProducts() {
  loadingMessage.value = 'Henter services';
  const productIds = new Set(tender.data.filteredVariants?.map((v) => v.productId) ?? []);
  return backend.pimDataService.fetchProductsByProductIds<Product0219>(tender.agreementName, [...productIds]);
}

function getVariantsByProduct(product: Product) {
  return pimIntegrationHelper.getVariantsByProduct(filteredVariants.value, product);
}

const filteredProducts = computed(() => pimIntegrationHelper.filteredProducts(products.value, filteredVariants.value));

async function fetchQuestionnaire() {
  return backend.questionnaireService
    .getReadonlyQuestionnaire(
      tender.id,
      'specification',
    );
}

async function onReject(variants: Variant0219[], product: Product0219, note: string) {
  try {
    startLoading();
    for (const variant of filteredVariants.value) {
      const foundVariant = variants.find((v) => v.id === variant.id);
      if (foundVariant) {
        foundVariant.deselectionReason = note;
        foundVariant.state = 'Rejected';
      }
    }
    const updateProduct = product;
    updateProduct.state = 'Rejected';

    await updateTender(product.id);
  } catch (error) {
    store.setSnackbarText('Kan ikke gemme ændringer i services');
    console.error('Error while rejecting:', error);
  } finally {
    stopLoading();
  }
}

async function onAccept(variants: Variant0219[], product: Product0219) {
  try {
    startLoading();
    for (const variant of filteredVariants.value) {
      const foundVariant = variants.find((v) => v.id === variant.id);
      if (foundVariant) {
        foundVariant.deselectionReason = undefined;
        foundVariant.state = 'Accepted';
      }
    }
    const updateProduct = product;
    updateProduct.state = 'Accepted';
    await updateTender(product.id);
  } catch (error) {
    store.setSnackbarText('Kan ikke gemme ændringer i services');
    console.error('Error while accepting:', error);
  } finally {
    stopLoading();
  }
}

async function onSave(variants: Variant0219[], product: Product0219) {
  try {
    startLoading();
    const updateProduct = product;
    const newState = calculateProductState(variants, product);
    updateProduct.state = newState;

    for (const variant of variants) {
      if (filteredVariants.value.some((filteredVariant) => filteredVariant.id === variant.id)) {
        variant.deselectionReason = undefined;
        variant.state = newState;
      }
    }
    await updateTender(product.id);
  } catch (error) {
    console.error('Error while saving:', error);
  } finally {
    stopLoading();
  }
}

async function updateTender(productId: number) {
  const updatedTender: UpdateTenderRequest0219 = {
    id: tender.id,
    data: {
      filteredVariants: filteredVariants.value.filter((x) => x.productId === productId),
      variantsDone: products.value.filter((p) => p.state === 'Draft' || p.state === 'Active').length === 0,
    },
  };
  await backend.tenderService.updateTender(updatedTender);
}

function openDialog(product: Product) {
  selectedProduct.value = product;
  pimIntegrationServiceDialog.value = true;
}

function isProductDeselected(product: Product) {
  return getVariantsByProduct(product).every((v) => v.deselectionReason);
}

function calculateProductState(variants: Variant0219[], product: Product0219): 'Active' | 'Draft' {
  const isFunctionalAddOnsCompleted = pimIntegrationHelper.isFunctionalAddOnsCompleted(
    variants,
    functionalAddOnsRefs.value,
    product,
  );

  const isSupplierSpecificIntegrationsCompleted = pimIntegrationHelper.isSupplierSpecificIntegrationsCompleted(
    variants,
    supplierSpecificIntegrationsRefs.value,
    product,
  );

  return isFunctionalAddOnsCompleted && isSupplierSpecificIntegrationsCompleted
    ? 'Active'
    : 'Draft';
}

const distinctVariantCount = computed(() => new Set(tender.data.filteredVariants?.map((v) => v.productId)).size);

function startLoading() {
  loadingMessage.value = 'Gemmer';
  isLoading.value = true;
}

function stopLoading() {
  isLoading.value = false;
}

function arraysHaveSameElements(arr1: number[], arr2: number[]): boolean {
  if (arr1.length !== arr2.length) {
    return false;
  }

  const sortedArr1 = [...arr1].sort();
  const sortedArr2 = [...arr2].sort();

  for (let i = 0; i < sortedArr1.length; i += 1) {
    if (sortedArr1[i] !== sortedArr2[i]) {
      return false;
    }
  }
  return true;
}

</script>

<style scoped>
h2,
h3 {
  text-transform: uppercase;
}

.scroll {
  overflow-y: scroll;
  height: 70vh;
}

.summary {
  background: rgb(255 249 239);
  max-width: 400px;
}
</style>
