import { Offer, Tender } from '@dims/components';
import { Offer0219 } from '@/models/Offer';
import { Tender0219, TenderData0219, ServiceAreasEnum, SystemTypesEnum, labelForServiceArea, labelForSystemType } from '@/models';

function systemTypeLabel(type?: SystemTypesEnum | null): string {
  return type ? labelForSystemType(type) : '';
}

function serviceAreaLabel(serviceArea?: ServiceAreasEnum | null): string {
  return serviceArea ? labelForServiceArea(serviceArea) : '';
}

export default {
  offerLabels(offer: Offer0219) {
    const { tenderData } = offer;
    return [
      systemTypeLabel(tenderData.systemType),
      serviceAreaLabel(tenderData.serviceArea),
    ];
  },
  offerDetails(_offer: Offer) {
    return '';
  },
  offerLongDescription(offer: Offer) {
    const { tenderData } = offer as Offer0219;
    return `${tenderData.description}`;
  },
  tenderLongDescription(tender: Tender) {
    return tender.description;
  },
  tenderValues(tender: Tender0219): { label: string, name: string, value: string }[] {
    const tenderData = tender.data;
    return [
      { label: 'Systemvalg', name: 'systemType', value: systemTypeLabel(tenderData.systemType) },
      { label: 'Ydelsesområde', name: 'serviceArea', value: serviceAreaLabel(tenderData.serviceArea) },
    ];
  },
  offerValues(offer: Offer): { label: string, name: string, value: string }[] {
    const tenderData = offer.tenderData as TenderData0219;
    return [
      { label: 'Systemvalg', name: 'systemType', value: systemTypeLabel(tenderData.systemType) },
      { label: 'Ydelsesområde', name: 'serviceArea', value: serviceAreaLabel(tenderData.serviceArea) },
    ];
  },
  getColumnValues(tender: Tender0219) {
    return [
      systemTypeLabel(tender.data.systemType),
      serviceAreaLabel(tender.data.serviceArea),
    ];
  },
  getTableHeaders() {
    return [
      {
        title: 'Systemvalg',
        align: 'start' as const,
        value: 'systemType',
        sort: (a: string, b: string) => systemTypeLabel(a as SystemTypesEnum)
          .localeCompare(systemTypeLabel(b as SystemTypesEnum)),
        sortable: true,
      },
      {
        title: 'Ydelsesområde',
        align: 'start' as const,
        value: 'serviceArea',
        sort: (a: string, b: string) => serviceAreaLabel(a as ServiceAreasEnum)
          .localeCompare(serviceAreaLabel(b as ServiceAreasEnum)),
        sortable: true,
      },
    ];
  },
  searchOffersText(search: string, offers: Offer0219[]): Offer[] {
    const searchString = search.toLowerCase();
    return offers.filter(
      (o) => o.sender.companyName
        .concat(o.tenderData.description ?? '') // TODO: Concatenate all text fields?
        .toLowerCase()
        .includes(searchString),
    );
  },
};
