<template>
  <TenderTerminationDirectAwardDialogBase
    :tender
    :disabled
    :conditionalOffers
    :unconditionalOffers
  >
    <template #page1-tender>
      <v-row>
        <v-col cols="4">
          <strong>Del:</strong>
          {{ part }}
        </v-col>
      </v-row>
    </template>
    <template #page1-winning-offer>
    </template>
  </TenderTerminationDirectAwardDialogBase>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import {
  DirectOffer,
  TenderTerminationDirectAwardDialogBase,
} from '@dims/components';
import { Tender0219 } from '@/models/Tender';

const { tender, disabled = false } = defineProps<{
  tender: Tender0219,
  disabled?: boolean,
  conditionalOffers: DirectOffer[],
  unconditionalOffers: DirectOffer[],
}>();

const part = computed(() => tender.data.serviceArea);

</script>
