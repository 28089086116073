import { ComponentProvider } from '@dims/components';
import SpecificationViewer from '@/components/Offers/Specification/SpecificationViewer.vue';
import OfferDetails from '@/components/Offers/OfferDetails/OfferDetails.vue';
import OfferSpecificationViewer from '@/components/Offers/Specification/OfferSpecificationViewer.vue';
import OfferSpecificationDialog from '@/components/Offers/Specification/OfferSpecificationDialog.vue';
import OfferSandbox from '@/components/Offers/OfferSandbox.vue';
import PublishTenderConfirmationDialog from '@/components/Tender/TenderOverview/PublishTenderConfirmationDialog.vue';
import TenderMaterial from '@/components/Tender/TenderMaterial/TenderMaterial.vue';
import TenderMaterialDirectAward from '@/components/Tender/TenderMaterial/TenderMaterialDirectAward.vue';
import SpecificationAttachedFiles from '@/components/Tender/TenderOverview/SpecificationAttachedFiles.vue';
import TenderTerminationDialog from '@/components/Tender/Evaluation/TenderTerminationDialog.vue';
import EvaluationOfferList from '@/components/Tender/Evaluation/EvaluationOfferList.vue';
import CreateTender from '@/components/create-tender/CreateTender.vue';
import HomeView from '@/views/HomeView.vue';
import EvaluationVariantsList from '@/components/Tender/Evaluation/EvaluationVariantsList.vue';
import PimServiceVariantsCounter from '@/components/Tender/Specification/PimServiceVariantsCounter.vue';
import PimIntegrationServicesTab from '@/components/Tender/Specification/PimIntegration/PimIntegrationServicesTab.vue';
import PimStepper from '@/components/Tender/Specification/PimIntegration/PimStepper.vue';
import PlaceholderComponent from '@/components/Tender/Specification/PlaceholderComponent.vue';
import PriceCalculatorComponent from './components/Tender/Evaluation/PriceCalculatorComponent.vue';

const componentProvider: ComponentProvider = {
  OfferDetails,
  SpecificationViewer,
  OfferSpecificationViewer,
  OfferSpecificationDialog,
  SpecificationAttachedFiles,
  OfferSandbox,
  PublishTenderConfirmationDialog,
  TenderTerminationDialog,
  CreateTender,
  TenderMaterial,
  TenderMaterialDirectAward,
  EvaluationOfferList,
  EvaluationVariantsList,
  PimServiceVariantsCounter,
  PimIntegrationServicesTab,
  PlaceholderComponent,
  PimStepper,
  HomeView,
  PriceCalculatorComponent,
};

export default componentProvider;
