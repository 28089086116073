import { PricePointValue } from '@dims/components';

export default {
  validatePricePointTexts(values: PricePointValue[]) {
    return !this.isTextsEmpty(values);
  },
  validatePricePointValues(values: PricePointValue[], errorMessages: readonly string[]) {
    const hasErrors = errorMessages.length > 0;
    const moreThanDailyOffered = values.find((x) => x.key === 'MoreThanDailyOffered')?.value;
    return moreThanDailyOffered === 'false'
     || (!hasErrors && !this.isTextsEmpty(values) && !this.isValuesEmpty(values));
  },
  pricePointErrorMessages(enabled: boolean, texts: PricePointValue[], values: PricePointValue[], errorMessages: readonly string[], _unit: string, _variant: string | null = null) {
    const moreThanDailyOffered = values.find((x) => x.key === 'MoreThanDailyOffered')?.value;
    const messages: string[] = [];
    if (moreThanDailyOffered === 'false') {
      messages.push('Prispunkt skal ikke ufyldes');
      return messages;
    }
    const hasErrors = errorMessages.length > 0;
    if (!enabled) {
      messages.push('Prispunkt er deaktiveret');
    } else {
      if ((this.isTextsEmpty(texts) || this.isValuesEmpty(values))) {
        messages.push('Alle felter skal udfyldes');
      }
      if (!hasErrors && this.validatePricePointTexts(texts) && (this.validatePricePointValues(values, errorMessages))) {
        messages.push('Prispunkt er aktiveret og korrekt udfyldt');
      }
    }
    if (hasErrors) messages.push(...errorMessages);
    return messages;
  },
  // Methods below are not part of interface in dims-components
  isTextsEmpty(values: PricePointValue[]) {
    return values.some((v) => v.value.length === 0);
  },
  isValuesEmpty(values: PricePointValue[]) {
    return values.some((v) => v.value.length === 0 || Number(v.value) < 0);
  },
};
