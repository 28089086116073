<template>
  <h2 class="mt-0">Beregningsmetode</h2>
  <br />
  <p>
    Den evalueringstekniske pris beregnes som summen af Tilbudsgiverens pris for servicen, pris for implementering samt evt. pris for LIS / FLIS, pris for uddannelse, pris for konsulentydelser samt pris for optioner, såfremt Kunden har tilvalgt disse.
    <br />
    <br />
    Evaluering af underkriteriet Pris vil ske på baggrund af den/de pris(er), som Tilbudsgiverne har angivet i deres tilbud for hver af de relevante prispunkter. Der beregnes en evalueringsteknisk pris på baggrund af de angivne priser, Kundens angivne beregningsenhed(er) og leveringskontraktens varighed, samt med hensyntagen til en indregnet procentvis prisregulering, som leverandøren angiver i sit tilbud.
    <br />
    <br />
    Prispunkterne LIS / FLIS og Optioner vægtes baseret på valg foretaget af Kunden.
    <br />
    <br />
    Der er op til 6 trin i beregningen, afhængigt af Kundens valg:
    <br />
    <br />
    Trin 1: Prisen for servicen
    <br />
    <br />
    Trin 2: Pris for implementering
    <br />
    <br />
    Trin 3: evt. Pris for LIS/FLIS (denne er vægtet baseret på Kundens valg)
    <br />
    <br />
    Trin 4: evt. Pris for Uddannelse
    <br />
    <br />
    Trin 5: evt. Pris for Konsulentydelser
    <br />
    <br />
    Trin 6: evt. Pris for Optioner (disse er vægtet baseret på Kundens angivelse af vægt)
    <br />
    <br />
    Såfremt tilbuddet omhandler et Fællesindkøb vil trin 2 og evt. trin 6 (afhængigt af prismodel valgt) skulle angives pr. deltager i Fællesindkøbet.
    <br />
    <br />
    Afslutningsvis opgøres den samlede evalueringstekniske pris ved at addere alle prispunkterne til en samlet sum. For at identificere en vinder, vil den samlede evalueringstekniske pris for de modtagne tilbud dernæst skulle evalueres op imod den kvalitative evaluering i henhold til ”kommissionsmodellen”.
  </p>
</template>

<script setup lang="ts">

</script>
