<template>
  <v-card>
    <v-container class="px-10">
      <h1>Udregning af TCO</h1>
      <br />
      <v-card-text class="pl-0">
        <h2 class="mt-0 mb-4">TODO</h2>
        <br />
        <div class="text-right">
          <span class="font-weight-bold">Samlet evalueringsteknisk pris (TCO):</span>
          <span class="text-success pl-2 double-underline">
            {{ $format.currency2(totalTcoPrice) }}
          </span>
        </div>
      </v-card-text>
      <div v-if="includeCloseButton" class="text-center">
        <v-btn @click="closeDialog()" class="mb-4 primary-button">
          Luk
        </v-btn>
      </div>
    </v-container>
  </v-card>
</template>

<script setup lang="ts">
import { computed } from 'vue';

const emit = defineEmits<{ closeDialog: [] }>();
const { includeCloseButton = false } = defineProps<{ includeCloseButton?: boolean }>();

function closeDialog() {
  emit('closeDialog');
}

const totalTcoPrice = computed((): number => 0);

</script>

<style scoped>
.double-underline {
  text-decoration-line: underline;
  text-decoration-style: double;
}

ul {
  list-style-type: square;
}

.calculation {
  font-weight: normal;
}

.sub-header {
  font-size: small;
  font-style: italic;
}
</style>
