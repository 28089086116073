export enum ServiceAreasPart1 {
  Y05 = 'Y05',
  Y10 = 'Y10',
  Y37 = 'Y37',
  Y38 = 'Y38',
  Y52 = 'Y52',
  Y56 = 'Y56',
  Y60 = 'Y60',
  Y65 = 'Y65',
  Y67 = 'Y67',
}

export enum ServiceAreasPart2 {
  Y05 = 'Y05',
  Y10 = 'Y10',
  Y14 = 'Y14',
  Y20 = 'Y20',
  Y24 = 'Y24',
  Y26 = 'Y26',
  Y37 = 'Y37',
  Y38 = 'Y38',
  Y52 = 'Y52',
  Y54 = 'Y54',
  Y60 = 'Y60',
  Y65 = 'Y65',
  Y67 = 'Y67',
}

export type ServiceAreasEnum =
  | ServiceAreasPart1
  | ServiceAreasPart2;

export const labelForServiceArea = (serviceArea: ServiceAreasEnum) => {
  switch (serviceArea) {
    case ServiceAreasPart1.Y05:
      return 'Y05 Samfundsstruktur';
    case ServiceAreasPart1.Y10:
      return 'Y10 Uddannelse og undervisning';
    case ServiceAreasPart2.Y14:
      return 'Y14 Arbejdsmarked';
    case ServiceAreasPart2.Y20:
      return 'Y20 Sundhed';
    case ServiceAreasPart2.Y24:
      return 'Y24 Institutionstilbud';
    case ServiceAreasPart2.Y26:
      return 'Y26 Social service og omsorg';
    case ServiceAreasPart1.Y37:
      return 'Y37 Miljø';
    case ServiceAreasPart1.Y38:
      return 'Y38 Natur og klima';
    case ServiceAreasPart1.Y52:
      return 'Y52 Fysisk planlægning og geodata';
    case ServiceAreasPart2.Y54:
      return 'Y54 Ejendomme og byggeri';
    case ServiceAreasPart1.Y56:
      return 'Y56 Energi- og vandforsyning';
    case ServiceAreasPart1.Y60:
      return 'Y60 Myndighedens personale';
    case ServiceAreasPart1.Y65:
      return 'Y65 Myndighedens kommunikation og dokumentation';
    case ServiceAreasPart1.Y67:
      return 'Y67 Myndighedens økonomi';
    default:
      return 'Unknown service area';
  }
};

export default ServiceAreasEnum;
