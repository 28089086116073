<template>
  <div>
    <v-row>
      <v-col
        class="d-flex"
        cols="12"
      >
        <v-select density="comfortable" variant="outlined" v-model="selectedServiceArea" :items="serviceAreas" data-cy="service-area-select">
        </v-select>
      </v-col>
    </v-row>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useStore0219 } from '@/store/store0219';
import { labelForServiceArea, SystemTypesEnum, ServiceAreasEnum, ServiceAreasPart1, ServiceAreasPart2 } from '@/models';

const store = useStore0219();

const selectedServiceArea = computed({
  get() {
    let serviceArea;
    if (store.draftTender) {
      serviceArea = store.draftTender.data.serviceArea;
    }
    return serviceArea ?? undefined;
  },
  set(serviceArea: ServiceAreasEnum | undefined) {
    if (store.draftTender) {
      store.setDraftTender({
        ...store.draftTender,
        data: { ...store.draftTender.data, serviceArea },
      });
    }
  },
});

const serviceAreas = computed(() => {
  let serviceAreaTypes: ServiceAreasEnum[] = [];
  if (store.draftTender?.data.systemType) {
    switch (store.draftTender.data.systemType) {
      case SystemTypesEnum.PartI:
        serviceAreaTypes = Object.values(ServiceAreasPart1);
        break;
      case SystemTypesEnum.PartII:
        serviceAreaTypes = Object.values(ServiceAreasPart2);
        break;
      case SystemTypesEnum.PartIII:
      default:
        return [];
    }
  }
  return serviceAreaTypes.map((s) => ({ title: labelForServiceArea(s), value: s }));
});

</script>
