<template>
  <div>
    <v-card class="bg-canvas">
      <v-row>
        <v-col cols="2" class="scroll">
          <v-card color="canvas pa-3" flat data-cy="service">
            <v-card-title>
              <h3>Service</h3>
            </v-card-title>
            <v-card-text>
              <v-autocomplete
                clearable
                v-model="selectedProductId"
                placeholder="Vælg produkt"
                @update:model-value="onProductChanged(selectedProductId)"
                :items="products?.service_0219"
                item-title="name"
                item-value="id"
                variant="outlined"
                :disabled="isLoading"
              >
              </v-autocomplete>
            </v-card-text>
          </v-card>
          <v-card color="canvas pa-3" flat>
            <v-card-title>
              <h3>Variant</h3>
            </v-card-title>
            <v-card
              v-for="(variant) in variants"
              :disabled="isLoading"
              :key="variant.id"
              :class="selectedVariant?.id === variant.id ? 'card-selected' : 'card'"
              style="overflow: hidden"
              @click="selectVariant(variant)"
              :data-cy-variant-id="variant.id"
            >
              <v-card-text>
                <v-row align="center">
                  <v-col>{{ variant.serviceVariantName_0219 }} ({{ selectedProduct?.supplier?.name }}, {{ selectedProduct?.name }})</v-col>
                  <v-col cols="auto"><PimIntegrationServiceLevelChips :serviceLevels="[variant.serviceLevel_0219 ?? 'ukendt']" /></v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-card>
        </v-col>
        <v-col style="height: 80vh;">
          <PriceCalculatorVariant
            v-if="selectedProduct && selectedVariant && references && priceParameterSet.has(selectedVariant.id) && educationCounts.has(selectedVariant.id)"
            :product="selectedProduct"
            :variant="selectedVariant"
            :priceParameterSet="priceParameterSet.get(selectedVariant.id)!"
            :references
            :allEducationCounts="educationCounts.get(selectedVariant.id)!"
          />
        </v-col>
        <v-col cols="2" class="scroll">
          <v-card color="canvas" flat>
            <v-card-title>
              <h3>Dokumenter</h3>
            </v-card-title>
            <v-card-text>
              <div v-for="asset in assets" :key="asset.id">
                <v-row v-if="assets">
                  <v-col>
                    <v-card>
                      <v-card-text>
                        {{ asset?.infoText }}
                      </v-card-text>
                      <v-card-actions>
                        <v-btn
                          @click="download(asset);"
                          prepend-icon="mdi-file-download-outline"
                        >
                          {{ asset?.name }}
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-col>
                </v-row>
              </div>
            </v-card-text>
          </v-card>

        </v-col>
      </v-row>
    </v-card>
    <LoadingSpinner :loadingMessage :visible="isLoading" />
  </div>
</template>

<script setup lang="ts">
import { useBackend, useStore, Asset } from '@dims/components';
import { computed, onMounted, ref } from 'vue';
import { DateTime } from 'luxon';
import { forEach } from 'lodash';
import { FunctionalAddOn0219 } from '@/models/PIM/FunctionalAddOn0219';
import { SupplierSpecificIntegration0219 } from '@/models/PIM/SupplierSpecificIntegration0219';
import { Product0219 } from '@/models/PIM/Product0219';
import { Variant0219 } from '@/models/PIM/Variant0219';
import { PriceParameterSet } from '@/models/PriceParameter';
import { ManagementInformation0219 } from '@/models/PIM/ManagementInformation0219';
import { KombitIntegration0219 } from '@/models/PIM/KombitIntegration0219';
import { PublicIntegration0219 } from '@/models/PIM/PublicIntegration0219';
import PriceCalculatorVariant from './PriceCalculatorVariant.vue';
import PimIntegrationServiceLevelChips from '../Specification/PimIntegration/PimIntegrationServiceLevelChips.vue';
import { EducationCount } from '@/models/EducationCount';

interface SupplierReferences_0219 {
  readonly service_0219: Product0219[];
  readonly functionalAddOn_0219: FunctionalAddOn0219[];
  readonly integrationPublicComponent_0219: PublicIntegration0219[];
  readonly kombitIntegration_0219: KombitIntegration0219[];
  readonly managementInfo_0219: ManagementInformation0219[];
  readonly supplierSpecificIntegration_0219: SupplierSpecificIntegration0219[];
}
const backend = useBackend();
const store = useStore();
const products = ref<SupplierReferences_0219|null>(null);
const references = ref<SupplierReferences_0219>();
const variants = ref<Variant0219[]>([]);
const educationCounts = ref<Map<number, EducationCount[]>>(new Map<number, EducationCount[]>());
const loadingMessage = ref('Henter dine services');
const isLoading = ref(false);
const selectedProductId = ref<number|null>(null);
const selectedProduct = computed(() => (products.value?.service_0219.find((x) => x.id === selectedProductId.value) ?? null));
const selectedVariant = ref<Variant0219|null>(null);
const assets = ref<Asset[]>([]);
const priceParameterSet = ref<Map<number, PriceParameterSet>>(new Map<number, PriceParameterSet>());

onMounted(async () => {
  isLoading.value = true;
  if (store.user) {
    products.value = await backend.pimDataService.fetchMyProducts<SupplierReferences_0219>(store.agreementId);
  }
  isLoading.value = false;
});

async function onProductChanged(productId: number|null) {
  priceParameterSet.value.clear();
  selectedVariant.value = null;
  if (productId) {
    loadingMessage.value = 'Henter servicevarianter';
    isLoading.value = true;
    const [variantsData, referencesData, assetsData] = await Promise.all([
      backend.pimDataService.variantsFromProductId<Variant0219>(productId, store.agreementId),
      backend.pimDataService.fetchProductReferences<SupplierReferences_0219>(productId, true),
      backend.pimDataService.fetchAssetsByProduct(productId)
    ]);
    variants.value = variantsData;
    references.value = referencesData;
    assets.value = assetsData;
    educationCounts.value = getEducationCounts();
    isLoading.value = false;
  } else {
    variants.value = [];
  }
}

function getEducationCounts() {
  const map = new Map<number, EducationCount[]>();
  forEach(variants.value, (variant) => {
    const ecs: EducationCount[] = [];
    ecs.push(
      { refId: variant.id, area: 'Service' },
    );
    if (references.value) {
      const selectedAddOns = references.value.functionalAddOn_0219;
      forEach(selectedAddOns, (a) => {
        ecs.push({ refId: a.id, area: a.name });
      });
    }
    map.set(variant.id, ecs);
  });
  return map;
}

function selectVariant(variant: Variant0219|null) {
  if (variant && selectedProduct.value && !priceParameterSet.value.has(variant.id)) {
    priceParameterSet.value.set(variant.id, {
      productId: selectedProduct.value.id,
      name: `Testberegning af pris for "${selectedProduct.value.name}" service`,
      contractDate: DateTime.now().toISODate(),
      priceRegulation: selectedProduct.value.priceRegulation_0219,
      option1: false,
      option2: false,
      option3: false,
      variantId: variant.id,
      functionalAddOnIds: [],
      supplierSpecificIntegrationIds: [],
      publicIntegrationIds: [],
      kombitIntegrationIds: [],
      serviceName: `${variant.serviceVariantName_0219} (${selectedProduct.value.supplier?.name})`,
      serviceLevel: variant.serviceLevel_0219,
      implementationType: 'NewImplementation',
    });
  }
  selectedVariant.value = variant;
}

function download(asset: Asset) {
  if (asset.url) {
    window.open(asset.url);
  } else {
    store.setSnackbarText('Dokument mangler');
  }
}

</script>

<style scoped>

.small-table {
  width: 50%;
}

.card, .card-selected {
  margin-bottom: 0.5em;
  padding: 0.5em 0.3em 0.5em 0.3em;
}

.card-selected {
  outline: 2px solid black; /* 'border-radius' not available */
}

.total-price {
  font-size: x-large!important;
  font-weight: bolder!important;
  color: green;
}

.scroll {
  overflow-y: scroll;
  height: 80vh;
}

.sub-header {
  font-size: small;
  font-style: italic;
}

:deep(.v-btn__content) {
    white-space: pre-wrap;
}
</style>
