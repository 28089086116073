<template>
  <div>
    <v-chip
      v-for="level in serviceLevels"
      :key="level"
      :color="serviceLevelColor(level)"
      variant="flat"
      class="mr-1"
      size="small"
    >{{ level }}</v-chip>
  </div>
</template>
<script setup lang="ts">

const { serviceLevels } = defineProps<{ serviceLevels: string[] }>();

function serviceLevelColor(serviceLevel: string) {
  switch (serviceLevel) {
    case 'Platin':
      return 'silver';
    case 'Guld':
      return 'amber';
    case 'Sølv':
      return 'grey lighten-2';
    case 'Bronze':
      return 'brown lighten-2';
    default:
      return 'grey';
  }
}

</script>
