/** Specifies IDs from the delivery agreement
 * Question IDs are named 'id' + a descriptive name for the question
 * Answer IDs are named 'id' + a descriptive name for the question
 * + 'Answer' + a descriptive name for the answer
 * Some IDs can be accessed through corresponding enums
*/
const deliveryAgreementIds = {
  idDeliveryDate: '5b988e29-1c11-4bde-a3e8-90d3503325e6',
  idFunctionality: '5f28fb2e-99cc-48ef-922b-5690dda65469',
  idTransition: '2b779400-d3e8-46cc-a841-26fbd7d9c1bc',
  idCollaboration: 'c6f7476b-aec2-409f-b20e-c2d1c454af26',
  idSecurity: '1ed643c0-8187-4b4e-b8f3-6896153302ce',
  idGreenInitiatives: '145b3802-96a2-47ae-bd54-c3e65b797e80',
};

export default deliveryAgreementIds;
