<template>
  <div>
    <v-dialog v-if="!readonly" v-model="dialog" scrollable>
      <template #activator="{ props }">
        <ArrowButton v-bind="props" class="my-5">Vælg integrationer</ArrowButton>
      </template>
      <v-container fluid>
        <v-card :title="question.title">
          <template #append>
            <v-btn icon @click="dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
          <v-card-text>
            {{ question.helpText }}
          </v-card-text>
          <v-divider class="my-0"></v-divider>
          <v-card-text>
            <v-data-table
              v-model="selected"
              :loading="isLoading"
              loading-text="Henter data..."
              :headers
              :search
              :items="displayData"
              item-value="value.integrationPublicComponentId"
              :sort-by="[{ key: 'value.integrationPublicComponentId' }]"
              class="bg-canvas"
              show-select
              dense
              :items-per-page-options="[10, 20, 50, -1]"
              no-data-text="Ingen integrationer"
              no-results-text="Din søgning fandt ingen integrationer"
            >
              <template #top>
                <v-toolbar flat color="canvas">
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Søg"
                    single-line
                    variant="outlined"
                    clearable
                    density="compact"
                    hide-details
                  ></v-text-field>
                </v-toolbar>
              </template>
              <template
                #item.value.integrationPublicComponentUseCaseNumbers="{
                  item,
                }"
              >
                <td>
                  <v-chip
                    class="useCaseChip"
                    size="x-small"
                    v-for="useCase in item.value
                      .integrationPublicComponentUseCaseNumbers"
                    :key="useCase"
                  >
                    {{ useCase }}</v-chip>
                </td>
              </template>
              <template
                #item.value.integrationPublicComponentName="{ item }"
              >
                <td>{{ item.value.integrationPublicComponentName }}</td>
              </template>
              <template
                #item.value.integrationPublicComponentMainUnit="{ item }"
              >
                <td>{{ item.value.integrationPublicComponentMainUnit }}</td>
              </template>
              <template
                #item.value.integrationPublicComponentUnit="{ item }"
              >
                <td>{{ item.value.integrationPublicComponentUnit }}</td>
              </template>
            </v-data-table>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn class="secondary-button" @click="dialog = false">
              Annuller
            </v-btn>
            <v-btn class="primary-button" @click="save()"> Gem </v-btn>
            <v-spacer />
          </v-card-actions>
        </v-card>
      </v-container>
    </v-dialog>
    <v-table dense class="mt-5 bg-canvas">
      <template #default>
        <thead>
          <tr>
            <th class="text-left">Navn</th>
            <th class="text-left">Overordnet enhed</th>
            <th class="text-left">Enhed</th>
          </tr>
        </thead>
        <tbody v-if="components?.length">
          <tr
            v-for="c in convertToDisplayData(components)"
            :key="c.value?.integrationPublicComponentId"
          >
            <td>
              {{
                `${
                  c.value?.integrationPublicComponentName
                } (${c.value?.integrationPublicComponentUseCaseNumbers.join(
                  ", ",
                )})`
              }}
            </td>
            <td>{{ c.value?.integrationPublicComponentMainUnit }}</td>
            <td>{{ c.value?.integrationPublicComponentUnit }}</td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="3" class="text-center">Ingen integrationer tilvalgt</td>
          </tr>
        </tbody>
      </template>
    </v-table>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, watch } from 'vue';
import {
  PlaceholderQuestion,
  useBackend,
  GlobalListValue,
} from '@dims/components';
import {
  IntegrationPublicComponent,
} from '@/models/PIM/GlobalLists/IntegrationPublicComponent';
import pimService from '@/services/pim.service';
import { PublicIntegrationPlaceholderValue } from '@/models/PIM/PlaceholderValue';
import { SystemTypesEnum, Tender0219 } from '@/models';
import { questionnaireHelper0219 } from '@/services/questionnaireHelper0219';

interface DisplayIntegrationPublicComponent {
  integrationPublicComponentId: string;
  integrationPublicComponentName: string;
  integrationPublicComponentMainUnit: string;
  integrationPublicComponentUnit: string;
  integrationPublicComponentUseCaseNumbers: string[];
}

const emit = defineEmits<{ addPlaceholderValue: [PlaceholderQuestion] }>();
const { tender, question, readonly = false } = defineProps<{
  tender: Tender0219,
  question: PlaceholderQuestion,
  readonly?: boolean,
}>();
const backend = useBackend();
const selected = ref<(string|undefined)[]>([]);
const dataProp = ref<GlobalListValue<IntegrationPublicComponent>[]>([]);
const displayData = ref<GlobalListValue<DisplayIntegrationPublicComponent>[]>([]);
const requestedUseCases = ref<string[]>([]);
const search = ref('');
const dialog = ref(false);
const isLoading = ref(false);
const headers = [
  {
    title: 'Use cases',
    value: 'value.integrationPublicComponentUseCaseNumbers',
  },
  { title: 'Navn', value: 'value.integrationPublicComponentName' },
  {
    title: 'Overordnet enhed',
    value: 'value.integrationPublicComponentMainUnit',
  },
  { title: 'Enhed', value: 'value.integrationPublicComponentUnit' },
];

const components = computed(() => {
  const placeholderValue = question
    .placeholderValue as PublicIntegrationPlaceholderValue | undefined;
  if (!placeholderValue?.values) {
    return [];
  }
  const globalListValues = placeholderValue.values;
  return globalListValues;
});

watch(
  dialog,
  async () => {
    if (dialog.value) {
      await fetchData();
    }
  },
);

async function fetchData() {
  isLoading.value = true;
  dataProp.value = await fetchGlobalListValues();
  const { questions } = await fetchQuestionnaire();
  if (tender.data.systemType === SystemTypesEnum.PartI) {
    requestedUseCases.value = questionnaireHelper0219.loadUseCases(
      questions,
      questionnaireHelper0219.useCasesPartIQuestionId,
    );
  } else if (tender.data.systemType === SystemTypesEnum.PartII) {
    requestedUseCases.value = questionnaireHelper0219.loadUseCases(
      questions,
      questionnaireHelper0219.useCasesPartIIQuestionId,
    );
  }
  dataProp.value = dataProp.value.filter(
    (i) => i.value?.integrationPublicComponentUseCase_0219.useCaseNumber_0219
        && requestedUseCases.value.includes(
          i.value.integrationPublicComponentUseCase_0219.useCaseNumber_0219,
        ),
  );
  displayData.value = convertToDisplayData(dataProp.value);
  selected.value = displayData.value.filter((i) => components.value.some(
    (c) => c.value?.integrationPublicComponentName_0219
          === i.value?.integrationPublicComponentName,
  )).map((x) => x.value?.integrationPublicComponentId);
  isLoading.value = false;
}

async function fetchGlobalListValues() {
  const globalListValues = await backend.pimDataService.fetchGlobalListValues<IntegrationPublicComponent>(
    'IntegrationPublicComponent_0219',
  );
  return pimService.filterIntegrationPublicComponentFromTenderData(
    globalListValues,
    tender.data,
  );
}

function convertToDisplayData(data: GlobalListValue<IntegrationPublicComponent>[]) {
  const displayDataConst: GlobalListValue<DisplayIntegrationPublicComponent>[] = [];
  data.forEach((d) => {
    if (
      d.value?.integrationPublicComponentUseCase_0219.useCaseNumber_0219
    ) {
      const displayDataItem = displayDataConst.find(
        (dd) => dd.value?.integrationPublicComponentName
            === d.value?.integrationPublicComponentName_0219,
      );
      if (
        displayDataItem?.value
          && !displayDataItem.value.integrationPublicComponentUseCaseNumbers.includes(
            d.value.integrationPublicComponentUseCase_0219.useCaseNumber_0219,
          )
      ) {
        displayDataItem.value.integrationPublicComponentUseCaseNumbers.push(
          d.value.integrationPublicComponentUseCase_0219.useCaseNumber_0219,
        );
        displayDataItem.value.integrationPublicComponentUseCaseNumbers = displayDataItem.value.integrationPublicComponentUseCaseNumbers.sort();
      } else {
        displayDataConst.push({
          value: {
            integrationPublicComponentId:
                d.value.integrationPublicComponentId_0219,
            integrationPublicComponentName:
                d.value.integrationPublicComponentName_0219,
            integrationPublicComponentMainUnit:
                d.value.integrationPublicComponentMainUnit_0219,
            integrationPublicComponentUnit:
                d.value.integrationPublicComponentUnit_0219,
            integrationPublicComponentUseCaseNumbers: [
              d.value.integrationPublicComponentUseCase_0219
                .useCaseNumber_0219,
            ],
          },
          sortOrder: d.sortOrder,
          uid: d.uid,
        });
      }
    }
  });
  return displayDataConst;
}

function save() {
  const selectedConst = dataProp.value.filter((i) => selected.value
    .includes(i.value?.integrationPublicComponentId_0219));
  const placeholderValue = {
    type: 'PimIntegration',
    values: selectedConst,
  } satisfies PublicIntegrationPlaceholderValue;
  question.placeholderValue = placeholderValue;
  emit('addPlaceholderValue', question);
  dialog.value = false;
}

async function fetchQuestionnaire() {
  return backend.questionnaireService.getReadonlyQuestionnaire(
    tender.id,
    'specification',
  );
}

</script>

<style scoped>
.useCaseChip {
  margin: 1px;
}
</style>
