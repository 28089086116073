<template>
  <BpqDocumentationDialogBase
    :offer
    :index
    :isOfferConditional
    :criterias="createCriterias()"
    :offerScore="offer.data.bpqPointScore"
    :bpqPointScoreMinimum="tender.data.bpqPointScoreMinimum"
    :tcoPrice="offer.data.tcoCalculationResult?.tcoPrice"
    weightsInPoints
    :firstColumnWidth="5"
  />
</template>

<script setup lang="ts">
import { OfferEvaluation, WeightedScoreViewModel, BpqDocumentationDialogBase } from '@dims/components';
import { Tender0219 } from '@/models/Tender';
import { Offer0219 } from '@/models/Offer';
import bpqCriteria from '@/services/bpqCriteria';

const { tender, offer, offerEvaluation } = defineProps<{
  tender: Tender0219,
  offer: Offer0219,
  offerEvaluation?: OfferEvaluation,
  isOfferConditional: boolean,
  index: number,
}>();

function createCriterias() {
  if (offer.isEvaluated && offerEvaluation) {
    return bpqCriteria.getCategories(tender.data, offerEvaluation)
      .map((c) => new WeightedScoreViewModel(c.key, c.label, c.score, c.weight));
  }

  return bpqCriteria.getEmptyCategories(tender.data)
    .map((c) => new WeightedScoreViewModel(c.key, c.label, c.score, c.weight));
}
</script>
