import {
  DocumentDrafterQuestionnaire,
  RequestForOfferQuestionnaireProvider,
  Tender, useBackend,
  AnswerSetBuilder,
} from '@dims/components';
import RequestQuestionnaireValidator from './RequestQuestionnaireValidator';

class RequestQuestionnaireProvider implements RequestForOfferQuestionnaireProvider {
  backend = useBackend();
  constructor(readonly tender: Tender) { }

  activatorLabel = 'Leveringskontrakt og Opfordring';
  hasPreviewableCsr = false;

  async getInitialPushAnswers(_answers: AnswerSetBuilder): Promise<void> {
    return Promise.resolve();
  }

  getValidator(questionnaire: DocumentDrafterQuestionnaire) {
    return new RequestQuestionnaireValidator(this.tender, questionnaire);
  }
}

export default function getProvider(tender: Tender) {
  return new RequestQuestionnaireProvider(tender);
}
