<template>
  <div>
    <h2 class="mt-0">VEJLEDNING</h2>
    <h3 class="mt-3">Her skal du</h3>
    <p>
      Gennemgå alle services og vurdere om du ønsker at tilkøbe én eller flere af de tilgængelige tillægsydelser og/eller leverandørspecifikke integrationer.
    </p>
    <ol>
      <li>Læs leverandørens use case besvarelser</li>
      <li>Læs leverandørens tilbudte funktionelle tillægsydelser og vælg dem du ønsker at tilkøbe</li>
      <li>Læs leverandørens tilbudte leverandørspecifikke integrationer og vælg dem du ønsker at tilkøbe</li>
    </ol>
    <br>
    <p>
      Hvis en service ikke opfylder dine behov kan du markere "FRAVÆLG" på servicen, og notere begrundelsen. Denne note gemmes som en "Intern note"
    </p>
    <v-divider></v-divider>
    <v-progress-circular
      v-if="isLoading"
      indeterminate
      color="accent"
      class="mt-5"
    >
    </v-progress-circular>
    <div v-for="(t, idx) in titles" :key="idx">
      <template v-if="sections(idx).length">
        <h3 class="mt-5 text-uppercase">{{ t }}</h3>
        <div>
          <div v-for="p in sections(idx)" :key="p.id" class="d-flex align-start">
            <v-icon class="mr-1">{{ icons[idx] }}</v-icon> {{ p.name }}
          </div>
        </div>
      </template>
    </div>
    <div v-if="!isLoading">
      <p v-if="readyForEvaluation" class="my-4">
        Din kravspecifikation er færdig og klar til evaluering. Markér kravspecifikationen som 'færdig' i næste trin og fortsæt til evalueringsfanen.
      </p>
      <p v-else class="my-4">Når alle services er gennemgået, er din kravspecifikation klar til evaluering.</p>
      <EventButton @eventButtonClick="closeDialog" :disabled="!readyForEvaluation">Fortsæt</EventButton>
    </div>
  </div>
</template>
<script setup lang="ts">
import { computed } from 'vue';
import { Product0219 } from '@/models/PIM/Product0219';

const emit = defineEmits<{ closeDialog: [] }>();
const { isLoading = false, products } = defineProps<{
  isLoading?: boolean,
  products: Product0219[],
}>();

const titles = ['Gennemgå', 'Klar til evaluering', 'Fravalgt'];
const icons = ['mdi-pencil-circle-outline', 'mdi-check-circle-outline', 'mdi-close-circle-outline'];

function sections(idx: number): Product0219[] {
  switch (idx) {
    case 0:
      return products.filter((p) => p.state === 'Draft' || p.state === 'Active');
    case 1:
      return products.filter((p) => p.state === 'Accepted');
    case 2:
      return products.filter((p) => p.state === 'Rejected');
    default:
      return [];
  }
}

const readyForEvaluation = computed(() => products.every((p) => p.state === 'Accepted' || p.state === 'Rejected'));

function closeDialog() {
  emit('closeDialog');
}

</script>
