import '@dims/components/dist/dims-components.css';
import { RouteRecordRaw } from 'vue-router';
import { init } from '@dims/components';
import RiskAssessmentGeneralView from '@/views/RiskAssessmentGeneralView.vue';
import componentProvider from '@/componentProvider';
import App from '@/App.vue';

const routes: RouteRecordRaw[] = [];

routes.push({
  path: '/riskAssessment',
  name: 'Risikovurderingsværktøj',
  component: RiskAssessmentGeneralView,
});

const siteConfig = {
  agreementId: '02190023',
  agreementNameLong: '02.19 Fagsystemer',
};

const siteTexts = {
  myTendersSubHeader: 'Opret nye indkøb for både rammeaftalen og det dynamiske indkøbssystem, se dine aktive indkøb, kladder og afsluttede indkøb.',
};

init(App, document, siteTexts, siteConfig, componentProvider, routes);
