export enum SystemTypesEnum {
  PartI = 'Del_I',
  PartII = 'Del_II',
  PartIII = 'Del_III',
}

export const labelForSystemType = (systemType: SystemTypesEnum) => {
  switch (systemType) {
    case SystemTypesEnum.PartI:
      return 'Del I - Rammeaftale (stat, regioner og øvrige offentlige organisationer)';
    case SystemTypesEnum.PartII:
      return 'Del II - Rammeaftale (kommuner)';
    case SystemTypesEnum.PartIII:
      return 'Del III - Dynamisk Indkøbssystem';
    default:
      return 'Unknown system type';
  }
};

export default SystemTypesEnum;
