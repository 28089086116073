import { DeepQuestion, QuestionnaireHelper, GlobalListValue } from '@dims/components';

export default class QuestionnaireHelper0219 extends QuestionnaireHelper {
  readonly contractLengthQuestionId = 'bc8796b2-4473-45f1-a822-12e87c0cfc10';
  readonly contractStartQuestionId = '938db0d5-499c-4215-96a8-9c97ffcbf1f7';
  readonly option1QuestionId = 'adfc9fe8-4856-413b-8238-8bb99ffb7637';
  readonly option2QuestionId = '88027876-f5d3-4bce-a0fd-799cec6d0ea2';
  readonly option3QuestionId = '7abbee59-545b-4356-9927-0d805b9bd655';
  readonly option3CountQuestionId = '9853c428-b5f9-4cb0-a7b9-af70f9e039c5';
  readonly juniorConsultHoursQuestionId = 'c05db34a-da97-4e5d-80b0-e1f1f5c4587f';
  readonly standardConsultHoursQuestionId = '3fc4e8e9-2b86-44ee-9480-4c0e7d68882a';
  readonly seniorConsultHoursQuestionId = 'bc6d3650-b167-4860-8544-c2fbcd8d7f95';
  readonly chiefConsultHoursQuestionId = '2cb5ea68-64d5-4525-8048-b8a689245b87';
  readonly managementInfoQuestionId = 'cfc14b3c-a514-49f9-90f6-4445c5e05ba6';
  readonly commonManagementInfoQuestionId = 'b66e35fa-894c-48d8-adf6-cb7423739129';
  readonly publicIntegrationsQuestionId = '0d61a1d0-8609-4c9f-ab51-591007355edd';
  readonly kombitIntegrationsQuestionId = '93ba4050-16b8-40cf-9a23-0cd5d041a990';
  readonly useCasesPartIQuestionId = '6d60455d-6535-44ba-be2b-971384d8047d';
  readonly useCasesPartIIQuestionId = 'a30db51e-fd30-461b-b9a5-8565777b67f7';

  loadUseCases(questions: DeepQuestion[], questionId:string): string[] {
    const answer: string[] = [];
    const rawq = this.locateQuestion(questions, questionId);
    if (rawq?.questions) {
      rawq.questions.forEach((q) => {
        if (q.questions?.length === 1) {
          const subQuestion = q.questions[0];
          if (subQuestion && subQuestion.questionType === 'BooleanOptions' && subQuestion.value === 'true') {
            const match = q.title?.match(/UC\.Y[0-9]+\.I+\.[0-9]+/);
            if (match?.length && match.length > 0) { answer.push(match[0]); }
          }
        }
      });
    }
    return answer;
  }

  isYes(questions: DeepQuestion[], questionId:string) {
    const q = this.locateQuestion(questions, questionId);
    if (q && q.questionType === 'BooleanOptions') {
      return q.value === 'true';
    }
    return false;
  }

  loadGlobalListValues<T>(questions: DeepQuestion[], questionId:string): GlobalListValue<T>[] {
    const globalListValues = this.loadPlaceholderGlobaLValue<T>(questions, questionId);
    return globalListValues;
  }

  loadContractLengthIncludingYearsWithOptions(questions: DeepQuestion[], questionId: string): number {
    const lengthInYears = this.loadNumValue(questions, questionId) + 2;
    return lengthInYears;
  }

  // TODO #21857: There seeme to be a type parameter bug in loadPlaceholderValue() which cause this problem.
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-parameters
  loadTransformedPlaceholderValue<T, U>(questions: DeepQuestion[], questionId:string, products: U[], idPredicate: (arg1: T, arg2: U) => boolean): U[] {
    const answer:U[] = [];
    const globalListValues = this.loadPlaceholderValue<T>(questions, questionId);
    globalListValues.forEach((g) => {
      const product = products.filter((p) => idPredicate(g, p));
      if (product.length > 0) answer.push(...product);
    });
    return answer;
  }
}

export const questionnaireHelper0219 = new QuestionnaireHelper0219();
