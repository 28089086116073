<template>
  <div class="pt-5">
    <v-card>
      <v-card-text>
        <v-data-table
          v-model="calculationUnitValues"
          :loading="isLoading"
          loading-text="Henter data..."
          :headers
          :items="calculationUnitValues"
          :group-by="[{ key: 'participant' }]"
          :sort-by="[{ key: 'participant' }, { key: 'name' }]"
          class="bg-canvas"
          dense
          no-gutters
          :items-per-page="20"
          :items-per-page-options="[10, 20, 50, -1]"
          no-data-text="Ingen deltagere oprettet eller ingen beregningsenheder valgt"
        >
          <template
            #item.description="{ item }"
          >
            <div v-html="item.description" />
          </template>
          <template
            #item.count="{ item }"
          >
            <NumericInput class="py-1" density="compact" variant="outlined" v-model="item.count" :readonly hide-details />
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, watch } from 'vue';
import { PlaceholderQuestion, NumericInput } from '@dims/components';
import { cloneDeep } from 'lodash';
import { ParticipantCalculationUnitPlaceholderValue } from '@/models/PIM/PlaceholderValue';

const emit = defineEmits<{ addPlaceholderValue: [PlaceholderQuestion] }>();
const { question, readonly = false } = defineProps<{
  question: PlaceholderQuestion,
  readonly?: boolean,
}>();
const isLoading = ref(false);
const calculationUnitValues = computed(() => (question.placeholderValue
  ? ((question.placeholderValue as ParticipantCalculationUnitPlaceholderValue).values ?? [])
  : []));
const headers = [
  { title: 'Enhed', value: 'name', width: '100px' },
  { title: 'Beskrivelse', value: 'description', width: '60%' },
  { title: 'Antal', value: 'count', width: '10%' },
];
const debounceSave = ref(0);

watch(
  () => cloneDeep(calculationUnitValues.value.map((x) => x.count)),
  (current, old) => { onChange(current, old); },
);

function onChange(current: (number|undefined)[], old: (number|undefined)[]) {
  if (old.length !== current.length
     || old.some((x, idx) => x !== current[idx])) {
    if (debounceSave.value) {
      window.clearTimeout(debounceSave.value);
    }
    debounceSave.value = window.setTimeout(() => {
      save();
    }, 2000);
  }
}

function save() {
  const selected = calculationUnitValues.value;
  const placeholderValue = {
    type: 'CalculationUnit',
    values: selected,
  } satisfies ParticipantCalculationUnitPlaceholderValue;
  question.placeholderValue = placeholderValue;
  emit('addPlaceholderValue', question);
}

</script>
<style scoped>
.table-header {
  font-family: Oswald, sans-serif;
}
</style>
