import { MaterialsConfig } from '@dims/components';

const materialsConfig: MaterialsConfig = {
  hasSpecificationAttachedFiles: true,
  hasTripartiteAgreement: true,
  hasCsr: true,

  filesRequiringSupplierFilling: ['66dcd552-0fc0-45a2-a214-1d6e4be0eca5', '4edceea9-56c6-4fc2-80c7-22ab9752cd5e', 'a1a1444a-5a16-4283-8b71-227766f036e1', 'bdfc1e23-e8be-4db8-b30b-a28d8459dae4'],
};

export default materialsConfig;
