import { useStore } from '@dims/components';
import { defineStore } from 'pinia';
import { DraftTender0219 } from '@/models';

export const useStore0219 = defineStore('0219', {
  getters: {
    draftTender() {
      const store = useStore();
      return store.draftTender as DraftTender0219 | null;
    },
  },
  actions: {
    setDraftTender(tender: DraftTender0219) {
      const store = useStore();
      store.setDraftTender(tender);
    },
  },
});
