<template>
  <v-row>
    <v-col>
      <v-table :class="tableStyle">
        <template #default>
          <tbody>
            <tr v-for="(question, idx) of questions" :key="idx">
              <td>
                <v-icon
                  class="mr-3"
                  :color="question.complete ? 'primary' : ''"
                >{{
                  question.complete
                    ? 'mdi-check-circle'
                    : 'mdi-close-circle-outline'
                }}</v-icon>{{ question.title }}
              </td>
              <td style="text-align: end">
                <div v-if="showPrice(question)">
                  {{ $format.currency2(sumOfQuestion(question)) }}
                </div>
                <small v-else class="font-italic">
                  Kunden har fravalgt prispunkter under dette ydelsesområde,
                  hvorfor der ikke skal afgives priser</small>
              </td>
            </tr>
            <tr>
              <td>
                <div class="font-italic">I alt</div>
              </td>
              <td style="text-align: end">
                <div class="text-decoration-underline">
                  {{ $format.currency(sumOfQuestions) }}
                </div>
              </td>
            </tr>
          </tbody>
        </template>
      </v-table>
    </v-col>
  </v-row>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { DeepQuestion, hidePrice, totalPrice } from '@dims/components';

const { questions, tableStyle } = defineProps<{
  questions: DeepQuestion[],
  tableStyle: string }>();

function showPrice(question: DeepQuestion) {
  return !hidePrice([question]);
}

function sumOfQuestion(question: DeepQuestion) {
  return totalPrice([question]);
}

const sumOfQuestions = computed(() => questions.reduce(
  (sum, question) => sum + sumOfQuestion(question),
  0,
));

</script>
